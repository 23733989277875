import 'rxjs/add/operator/finally';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  AgreementTemplateGetEditByIdDTO,

} from './model'

import { SwaggerException } from "@/shared/service-proxies/model";

import moment from 'moment';

export class AgreementDetailServiceProxy {
  private instance: AxiosInstance;
  private baseUrl: string;
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  constructor(baseUrl?: string, instance?: AxiosInstance) {
    this.instance = instance ? instance : axios.create();
    this.baseUrl = baseUrl ? baseUrl : "";
  }

  /** 
   * 合同模板管理---获取单条
   * @param name 
   * @param filterText 
   * @param sorting 
   * @param maxResultCount 
   * @param skipCount 
   */
  agreementTemplateGetEditById(id: string): Promise<AgreementTemplateGetEditByIdDTO> {
    let url_ = this.baseUrl + `/api/services/app/AgreementTemplate/GetEditById?id=${id}`;
    url_ = url_.replace(/[?&]$/, "");

    let options_ = <AxiosRequestConfig>{
      method: "GET",
      url: url_,
      headers: {
        "Accept": "text/plain"
      }
    };

    return this.instance.request(options_).then((_response: AxiosResponse) => {
      return this.processAgreementTemplateGetEditById(_response);
    });
  }
  protected processAgreementTemplateGetEditById(response: AxiosResponse): Promise<AgreementTemplateGetEditByIdDTO> {
    const status = response.status;
    let _headers: any = {};
    if (response.headers && typeof response.headers === "object") {
      for (let k in response.headers) {
        if (response.headers.hasOwnProperty(k)) {
          _headers[k] = response.headers[k];
        }
      }
    }
    if (status === 200) {
      const _responseText = response.data;
      return _responseText
    } else if (status !== 200 && status !== 204) {
      const _responseText = response.data;
      return throwException("An unexpected server error occurred.", status, _responseText, _headers);
    }
    return Promise.resolve<AgreementTemplateGetEditByIdDTO>(<any>null);
  }






}


function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
  if (result !== null && result !== undefined) {
    throw result;
  } else {
    throw new SwaggerException(message, status, response, headers, null);
  }
}


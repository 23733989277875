<template>
<a-spin :spinning="spinning">
  <div class="sign">
    <ul>
      <li v-for="(item, index) in list" :key="index">
        <div class="title">{{item.title}}</div>
        <div class="desc">{{item.desc}}</div>

        <div class="radio-item item" v-if="item.type === 'radio'">
          <a-form-item label="">
            <a-radio-group v-decorator="['radio-group']">
              <a-radio :style="radioStyle" :value="it.value" v-for="(it, i) in item.itemValue" :key="i">
                {{it.desc}}
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </div>

        <div class="text-item item" v-if="item.type === 'text'">
          <p>{{item.itemValue}}</p>
        </div>

        <div class="switch-item item" v-if="item.type === 'switch'">
          <a-form-item label="">
            <a-switch v-decorator="['switch', { valuePropName: 'checked', initialValue: false }]" />
          </a-form-item>
        </div>

        <div class="form-item item" v-if="item.type === 'form'">

        </div>

        <div class="droplist-item item" v-if="item.type === 'droplist'">
         <a-form-item label="" has-feedback>
          <a-select
            v-decorator="[
              'select',
              { rules: [{ required: true, message: 'Please select your country!' }] },
            ]"
            placeholder="Please select a country"
          >
            <a-select-option :value="it.value" v-for="(it, i) in item.itemValue" :key="i">
              {{it.desc}}
            </a-select-option>
          </a-select>
        </a-form-item>
        </div>

      </li>
    </ul>

    <!-- <div class="sign-type">

    </div>
    <div class="make-seal">

    </div>
    <div class="make-code">

    </div>
    <div class="agreement-rule">

    </div>
    <div class="validity-date">

    </div>

    <div class="seal-position">

    </div> -->

    <div class="btn-wrapper">
      <div class="wrapper">
        <a-button type="primary">保存</a-button>
      </div>
    </div>

  </div>
</a-spin>

</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { AgreementDetailServiceProxy } from '../services/agreement-detail-proxies'

const mockData = [
  {
    title: '签署方式',
    desc: '选择该合同类型需要签署的方式',
    type: 'radio',
    itemValue: [
      {value: '2', desc: '企业-员工双方签署(默认员工先行签署，企业再签署)'},
      {value: '2', desc: '员工单方签署'},
      {value: '3', desc: '企业单方签署'},
    ]
  },
  {
    title: '加盖印章',
    desc: '选择该合同类型加盖的企业印章',
    type: 'text',
    itemValue: '暂无有效电子印章,去创建'
  },
  {
    title: '合同编号生成',
    desc: '选择后发起合同签署时将自动生成合同编号',
    type: 'switch',
    itemValue: false
  },
  {
    title: '合同编号规则',
    desc: '选择后发起合同签署时将自动生成合同编号',
    type: 'form',
  },
  {
    title: '员工签署有效期',
    desc: '发起签署后，员工签署方如未在设定有效期时间签订，合同将自动撤回并返还额度',
    type: 'droplist',
    itemValue: [{value: '1', desc: '1'}, {value: '2', desc: '2'}, {value: '3', desc: '3'}]
  },
  {
    title: '印章定位方式',
    desc: '选择设置合同上签章的方式',
    type: 'radio',
    itemValue: [
      {desc: '固定位置定位(不支持企业批量确认签署)', value: '1'},
      {desc: '关键字定位(支持企业批量确认签署)', value: '2'},
    ]
  },
]

export default {
    name: "sign",
    mixins: [AppComponentBase],
    data() {
      return {
        spinning: false,
        agreementDetailService: null,
        list: mockData,
        radioStyle: {
          display: 'block',
          height: '30px',
          lineHeight: '30px',
        },
        
      }
    },
    created() {
      this.agreementDetailService = new AgreementDetailServiceProxy(
        this.$apiUrl,
        this.$api
      )
    },
    mounted() {
      this._agreementTemplateGetEditById()
    },
    methods: {
      async _agreementTemplateGetEditById() {
        this.spinning = true
        let res = await this.agreementDetailService.agreementTemplateGetEditById('2ca4ce4d-3b57-42c1-9230-08d895a64d93')
        this.spinning = false
      }
    },
    components: {

    }
}
</script>

<style lang="less" scope>
@import "../../../../styles/common.less";

.sign{
  padding: 24px 0 102px;
  margin: 0 auto;
  width: 80%;
  min-width: 1000px;
  ul{
    li{
      list-style: none;
      .title{
        margin-bottom: 12px;
        padding-bottom: 16px;
        color: #1c2438;
        font-size: 16px;
        border-bottom: 1px solid #f0f4f8;
        line-height: 1;
      }
      .desc{
        margin-bottom: 24px;
        font-size: 12px;
        line-height: 1.5em;
        color: #80848f;
      }
      .item{
        margin-bottom: 12px;
      }
    }
  }
  .btn-wrapper{
    bottom: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    left: 0;
    z-index: 20;
    background-color: #ecf5ff;
    .wrapper{
      // #tac;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

</style>
